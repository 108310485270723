import axios from "axios";
import { API_URL, GENERAL_ERROR } from "@/utils/constants";
import { toast } from "vue3-toastify";
import { useSessionStore } from "@/app-store/session";

export const actions = {
  async checkValidOrder({
    pickupTime,
    orderType,
    name,
    email,
    phone,
    deliveryNotes,
    pickupType,
    dineIn = {},
  }) {
    try {
      this.$patch({
        transactionStatus: "loading-online-payment",
        loadingTransaction: true,
      });
      const { userToken, guestToken } = useSessionStore();
      const {
        data: { ok },
      } = await axios({
        url: `${API_URL}/api/validate-embed-order`,
        method: "post",
        data: {
          pickupTime,
          orderType,
          name,
          email,
          phone,
          deliveryNotes,
          pickupType,
          ...dineIn,
        },
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      return ok;
    } catch ({ response }) {
      this.$patch({
        orderCompleted: false,
        transactionError:
          response && response.data && response.data.message
            ? response.data.message
            : GENERAL_ERROR,
        transactionStatus: "order-failed",
      });
    }
  },
  async placeOrder({
    pickupTime,
    orderType,
    name,
    email,
    phone,
    deliveryNotes,
    pickupType,
    dineIn = {},
    shopId,
  }) {
    try {
      this.$patch({
        transactionStatus: "loading-online-payment",
        loadingTransaction: true,
      });
      const { userToken, guestToken } = useSessionStore();
      const {
        data: { order },
      } = await axios({
        url: `${API_URL}/api/shops/${shopId}/place-embed-order`,
        method: "post",
        data: {
          pickupTime,
          orderType,
          name,
          email,
          phone,
          deliveryNotes,
          pickupType,
          ...dineIn,
        },
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      return order;
    } catch ({ response }) {
      this.$patch({
        orderCompleted: false,
        transactionError:
          response && response.data && response.data.message
            ? response.data.message
            : GENERAL_ERROR,
        transactionStatus: "order-failed",
      });
    }
  },

  async placeKioskOrder({
    orderType,
    name,
    email,
    paymentMethod,
    dineIn,
    buzzerOrTagId,
  }) {
    try {
      this.$patch({
        transactionStatus: "follow-pinpad",
        loadingTransaction: paymentMethod === "EFTPOS",
        placeOrderRequest: new AbortController(),
      });
      const { userToken, guestToken } = useSessionStore();
      await axios({
        url: `${API_URL}/api/kiosks/${localStorage.getItem(
          "kioskId"
        )}/place-order`,
        method: "post",
        data: {
          orderType,
          name,
          email,
          paymentMethod,
          buzzerOrTagId,
          ...dineIn,
        },
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
        signal: this.placeOrderRequest.signal,
      });
      this.$patch({
        transactionStatus: "order-success",
        orderCompleted: true,
        loadingTransaction: true,
      });
      if (!userToken) await useSessionStore().createGuestSession();
      return true;
    } catch ({ response }) {
      this.$patch({
        orderCompleted: false,
        transactionError:
          response && response.data && response.data.message
            ? response.data.message
            : GENERAL_ERROR,
        transactionStatus: "order-failed",
      });
    }
  },

  async getPromoCode({ code }) {
    try {
      const { userToken, guestToken } = useSessionStore();
      const { data } = await axios({
        url: `${API_URL}/api/promo-codes/${code}`,
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      return data.code;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },

  async applyPromoCode({ code }) {
    try {
      const { userToken, guestToken } = useSessionStore();
      const {
        data: { ok },
      } = await axios({
        url: `${API_URL}/api/promo-codes/${code}/apply`,
        method: "post",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      return ok;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },

  async clearPromoCode() {
    try {
      const { userToken, guestToken } = useSessionStore();
      const {
        data: { ok },
      } = await axios({
        url: `${API_URL}/api/me/clear-promo-code`,
        method: "post",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      return ok;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },

  async getLastOrder({ shopId }) {
    try {
      const { userToken, guestToken } = useSessionStore();
      const {
        data: { order },
      } = await axios({
        url: `${API_URL}/api/me/shops/${shopId}/last-order`,
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      return order;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
  },

  async cancelOrderTransaction({ kioskId, provider }) {
    try {
      const { userToken, guestToken } = useSessionStore();
      await axios({
        url: `${API_URL}/api/kiosks/${kioskId}/terminal/${provider}/cancel-transaction`,
        method: "post",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      if (this.placeOrderRequest)
        this.placeOrderRequest.abort("Order cancelled by customer");
    } catch ({ response }) {
      this.$patch({
        orderCompleted: false,
        transactionError:
          response && response.data && response.data.message
            ? response.data.message
            : GENERAL_ERROR,
        transactionStatus: "order-failed",
      });
    }
  },
};
